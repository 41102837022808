<template>
	<div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{'b-filter': true}">
					<button class="b-filter__button">
						<h2>Подписки</h2>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">									<!-- Наименование КР -->
								<div class="b-input b-input--dual b-input--dual b-input--clinical1 b-input--search">
									<span class="email-text">Подтвердите ваш e-mail для подписки</span>
										<v-otp-input
										ref="otpInput"
										class="otp-input"
										separator=" "
										:num-inputs="4"
										:should-auto-focus="true"
										:is-input-num="true"
										v-model="emailCode"
										@on-change="handleOnChange"
      								@on-complete="handleOnComplete"
									/>
									<!-- <div class="code-block">
										<input type="text" onkeyup="testJump(this)" oninput="this.value = this.value.slice(0, 1)" id="code-name" maxlength="1" v-model="emailCode[0]" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"   />
										<input type="text" onkeyup="testJump(this)" oninput="this.value = this.value.slice(0, 1)" id="code-name" maxlength="1" v-model="emailCode[1]" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"   />
										<input type="text" onkeyup="testJump(this)" oninput="this.value = this.value.slice(0, 1)" id="code-name" maxlength="1" v-model="emailCode[2]" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"   />
										<input type="text" onkeyup="testJump(this)" oninput="this.value = this.value.slice(0, 1)" id="code-name" maxlength="1" v-model="emailCode[3]" class="b-input__input-field1 b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"  />
									</div> -->
								</div>
								</div>

							<button @click="subscription3(emailCode)" class="b-button b-button--fill">
								<span>Отправить</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
    		<snackbar position="top-center" ref="snackbar" baseSize="100px" :wrapClass="''" color="info" :holdTime="4000"/>
		</div>
</template>

<script>
import iconSvg from "../../assets/icons/svg/filter.svg"
import iconCloseSvg from "../../assets/icons/svg/close.svg"
import iconSearchSvg from "../../assets/icons/svg/search.svg"
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import iconAnglerightSvg from "../../assets/icons/svg/angle-right.svg"
import iconDownloadSvg from "../../assets/icons/svg/download.svg"
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg"
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg"
import iconEditSvg from "../../assets/icons/svg/edit.svg"
import iconEyeSvg from "../../assets/icons/svg/eye.svg"

import { RingLoader } from '@saeris/vue-spinners'


import Dropdown from '../../components/Dropdown/index'
import Dashboards from '../../components/Dashboards/index'
import {mapGetters, mapActions, mapMutations} from 'vuex'


export default {
	name: 'listCr',
	components: { RingLoader, Dashboards, Dropdown, iconEditSvg, iconEyeSvg, iconSvg, iconSearchSvg, iconAngleDownSvg, iconCloseSvg, iconDownloadSvg, iconSortdownSvg, iconSortupSvg, iconAngleleftSvg, iconAnglerightSvg },
	computed: {
			...mapGetters({
				media: 'layout/getMedia',
				apiUrl: 'apiUrl',
				apiUrlPortal: 'apiUrlPortal',
				getCode: 'sub/getCode'
			}),
	},
	data: () => ({
		emailCode: [],
		rules: {
			required: v => (!!v || v === 0 || v.length === 0) || 'Обязательно для заполнения!',
			requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
		},
	}),
	methods: {
		...mapMutations({
			setCode: 'sub/setCode'
		}),
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		isNumberKey(evt) {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			return !(charCode > 31 && (charCode < 48 || charCode > 57));
		},
		changeCode(){
			console.log();
		},
		handleOnComplete(value) {
      this.emailCode = value
		},
		handleOnChange(value) {
			console.log('OTP changed: ', value);
		},
		 testJump(x){
			var ml = ~~x.getAttribute('maxlength');
			if(ml && x.value.length >= ml){
				do{
						x = x.nextSibling;
				}
				while(x && !(/text/.test(x.type)));
				if(x && /text/.test(x.type)){
						x.focus();
				}
			}
		},
		subscription3(emailCode) {
			this.setCode(emailCode)
			console.log(emailCode);
			this.api.put(`${this.apiUrl}api/Subscription/Confirm?code=${emailCode}`)
		.then(res => {
			console.log(res);
			if(res.data.success) {
				console.log(this.getCode);
				this.$router.push(`/subscription-third/${this.$route.params.email}/${emailCode}`)
			}
			else{
				this.$refs.snackbar.info(res.data.userMessage);
			}
		})

		},

},
	created() {
		console.log(this.$route.params.email);
	}
}
</script>

<style scoped lang="scss">
@import "../../views/Subscription/style/stylePages.css";
@import "../../views/Subscription/style/mobile.css";

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
    top: 42%;
}

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
	cursor: pointer;
}
.code-block{
	display: flex;
}
#code-name{
	margin-right: 5px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-input {
	 width: 300px;
    font-size: 20px;
	 margin-top: 10px;
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
	 div{
		input{
		margin-left: 5px;
	 }
	 }

  }


</style>
